<template>
  <bj-modal
    title="选择位置"
    :visible="visible"
    :class="$style.map"
    :width="800"
    :body-style="{ height: '500px', 'max-height': '600px', 'overflow-y': 'auto' }"
    @ok="mapOk"
    @cancel="mapCancel"
  >
    <div id="container" />
    <div :class="$style.search">
      <input id="tipinput" autocomplete="off" placeholder="请输入位置关键词" type="text" />
      <i class="ri-search-2-line" />
    </div>
  </bj-modal>
</template>

<script>
let AMap = window.AMap
let getAmap = () => {
  AMap = window.AMap
  if (!AMap) {
    setTimeout(() => {
      getAmap()
    }, 100)
  }
}
getAmap()

export default {
  name: 'BjMap',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      placeSearch: null,
      marker: null,
      center: [],
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          if (!this.center.length) {
            this.center = [104.065681, 30.653442]
          }
          this.map = new AMap.Map('container', {
            zoom: 8, //级别
            center: this.center, //中心点坐标
            viewMode: '3D', //使用3D视图
          })
          var auto = new AMap.Autocomplete({
            input: 'tipinput',
          })
          this.marker = new AMap.Marker({
            position: new AMap.LngLat(this.center[0], this.center[1]),
            title: '位置',
            draggable: true,
          })
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map,
          }) //构造地点查询类
          this.map.add(this.marker)
          AMap.event.addListener(auto, 'select', this.select)
        })
      }
    },
  },
  created() {
    this.getDefault()
  },
  methods: {
    mapOk() {
      let lnglat = this.marker.getPosition()
      let geocoder = new AMap.Geocoder({
        city: '全国',
      })
      geocoder.getAddress(lnglat, (status, { regeocode }) => {
        if (status === 'complete') {
          this.$emit('change', {
            location: regeocode.formattedAddress,
            latitude: lnglat.lat,
            longitude: lnglat.lng,
            adcode: regeocode.addressComponent.adcode,
          })
        }
      })
      this.$emit('update:visible', false)
    },
    mapCancel() {
      document.getElementById('tipinput').value = null
      this.$emit('update:visible', false)
    },
    select(e) {
      if (!e.poi.location) {
        this.$message.error('请选择具体位置！')
        return
      }
      this.map.setZoomAndCenter(15, [e.poi.location.lng, e.poi.location.lat])
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(e.poi.location.lng, e.poi.location.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '位置',
        draggable: true,
      })
      this.map.remove(this.marker)
      this.map.add(this.marker)
    },
    getDefault() {
      if (AMap) {
        let citysearch = new window.AMap.CitySearch()
        //自动获取用户IP，返回当前城市
        citysearch.getLocalCity((status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.city && result.bounds) {
              this.center = result.rectangle.split(';')[0].split(',')
            }
          }
        })
      }
    },
  },
}
</script>

<style lang="less" module>
.map {
  /* stylelint-disable-next-line no-descending-specificity */
  :global {
    .ant-modal-body {
      position: relative;
    }
  }

  .search {
    position: absolute;
    top: 30px;
    right: 30px;

    input {
      width: 200px;
      height: 36px;
      padding-left: 10px;
      background: #fff;
      border: none;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #000;
      font-size: 18px;
    }
  }
}
</style>

<style lang="less">
#container {
  width: 752px;
  height: 450px;
}
</style>
