<template>
  <div :class="$style.detail">
    <div :class="$style.box">
      <div :class="$style.setting">
        <p :class="$style.title">内容配置</p>
        <a-row class="mb-20">
          <a-col span="4">
            <span :class="$style.require">*</span>
            封面类型
          </a-col>
          <a-col span="20">
            <a-radio-group :value="params.cover_type" @change="typeChange">
              <a-radio v-if="coverShow" :value="0"> 单图 </a-radio>
              <a-radio v-if="coverShow" :value="1"> 三图 </a-radio>
              <a-radio v-if="!coverShow" :value="2"> 小图 </a-radio>
              <a-radio v-if="!coverShow" :value="3"> 大图 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4">
            <span :class="$style.require">*</span>
            上传封面
          </a-col>
          <a-col span="20">
            <selectMaterial
              :data="params.cover_atlas"
              :allow="['image']"
              :type="params.cover_type === 1 ? 'multiple' : 'single'"
              @change="coverChange"
            />
            <!-- <div :class="$style.coverBox">
            <div v-for="(item, index) in params.cover_atlas" :key="item.id" :class="$style.coverItem">
              <img :src="item.cover" />
              <div :class="$style.btn">
                <i class="ri-eye-line mr-10" @click="onPreview(item)" />
                <i class="ri-delete-bin-7-line" @click="onDeleteImg(index)" />
              </div>
            </div>
            <div v-if="addShow" :class="$style.coverDefault" @click="onAddCover()">
              <i class="ri-add-line" />
            </div>
          </div> -->
            <p class="tip mt-10 mb-20">建议尺寸800px*600px；大小不超过2M；格式支持JPG、PNG。</p>
          </a-col>
        </a-row>
        <a-row v-if="type === 'video'" class="mb-20">
          <a-col span="4"> <span :class="$style.require">*</span>视频介绍</a-col>
          <a-col span="20">
            <div :class="$style.textarea">
              <a-textarea
                v-model="params.introduce"
                :auto-size="{ minRows: 5, maxRows: 5 }"
                placeholder="在这里输入视频的介绍内容，以便用户了解。"
              />
            </div>
          </a-col>
        </a-row>
        <a-row v-if="type === 'audio'" class="mb-20">
          <a-col span="4"> <span :class="$style.require">*</span>音频介绍</a-col>
          <a-col span="20">
            <div :class="$style.textarea">
              <a-textarea
                v-model="params.introduce"
                :auto-size="{ minRows: 5, maxRows: 5 }"
                placeholder="在这里输入音频的介绍内容，以便用户了解。"
              />
            </div>
          </a-col>
        </a-row>
        <a-row v-if="type === 'image'" class="mb-20">
          <a-col span="4"> <span :class="$style.require">*</span>图册介绍</a-col>
          <a-col span="20">
            <div :class="$style.textarea">
              <a-textarea
                v-model="params.introduce"
                :auto-size="{ minRows: 5, maxRows: 5 }"
                placeholder="在这里输入图册的介绍内容，以便用户了解。"
              />
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4">内容摘要</a-col>
          <a-col span="20">
            <div :class="$style.textarea">
              <a-textarea
                v-model="params.summary"
                :auto-size="{ minRows: 5, maxRows: 5 }"
                :placeholder="
                  '选填，摘要在某些地方会显示。帮助读者快速了解内容，如不填则默认读取' + typeName + '的前60字。'
                "
                :max-length="60"
              />
              <span>{{ summaryLength }} / 60</span>
            </div>
          </a-col>
        </a-row>
        <a-row class="mb-20">
          <a-col span="4" :class="$style.line40">
            <span :class="$style.require">*</span>
            内容类型
          </a-col>
          <a-col span="20">
            <bj-cascader
              v-model="params.type_id"
              change-on-select
              :options="typeData"
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'children',
              }"
              :inner-search="false"
              expand-trigger="hover"
              placeholder="请选择内容所属类型"
              :allow-clear="false"
              :load-data="onLoadData"
              @blur="typeBlur"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4" :class="$style.line40">
            <span :class="$style.require">*</span>
            内容分组
          </a-col>
          <a-col span="20" :class="$style.group">
            <BjSelect
              v-model="params.group_id"
              key-field="value"
              inner-search
              placeholder="请选择内容所属分组"
              value-field="id"
              label-field="name"
              show-all
              :options="groupData"
            />
            <p class="tip mb-20">
              请选择内容所属的分组，分组是由文化主自己确立，需要管理内容分组请在左边菜单“<span
                class="primary cursor"
                @click="toGroup()"
                >内容分组</span
              >”中进行管理。
            </p>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4" :class="$style.line40">
            <span :class="$style.require">*</span>
            选择位置
          </a-col>
          <a-col span="20" :class="$style.location">
            <BjInput v-model="params.location" placeholder="请选择位置">
              <div slot="suffix">
                <a-popover title="位置信息">
                  <template slot="content">
                    <p>经度：{{ params.longitude }}</p>
                    <p>纬度：{{ params.latitude }}</p>
                    <p>地址：{{ params.real_location }}</p>
                  </template>
                  <i v-if="params.real_location" style="vertical-align: -6px" class="ri-question-line location-icon" />
                </a-popover>
                <span :class="$style.locationbtn" @click="onMapSelect()"><i class="ri-map-pin-line" />选择位置</span>
              </div>
            </BjInput>
            <p class="tip mb-20">请在地图上标注与文化内容相关的位置信息，以便用户能通过地理位置阅读不同的内容信息。</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4" :class="$style.line40">内容标签</a-col>
          <a-col span="20">
            <div :class="$style.tagBox">
              <div :class="$style.tagItem">
                <span v-for="(item, index) in params.tags" :key="index" :class="$style.tagText">
                  <span :class="$style.text" class="ellipsis-width">{{ item }}</span>
                  <i class="ri-close-line" @click="deleteTag(index)" />
                </span>
                <p v-if="!params.tags.length">请添加内容标签</p>
              </div>
              <div :class="$style.tagInput">
                <span v-show="tagShow" :class="$style.locationbtn" type="primary" @click="onTag()">
                  <i class="ri-add-line" />添加标签
                </span>
                <BjInput v-show="!tagShow" ref="tagInput" v-model="tag" @pressEnter="tagChange" />
              </div>
            </div>
            <p class="tip mt-5 mb-20">输入标签内容后点击回车键即可添加标签。</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4">原创声明</a-col>
          <a-col span="20">
            <a-switch v-model="params.is_original_declare" />
            <p class="tip mt-5 mb-20">原创的内容可以进行原创声明。</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="4">允许评论</a-col>
          <a-col span="20">
            <bj-switch v-model="params.comment_status" size="default" />
            <p class="tip mt-5 mb-20">
              评论默认审核机制为“智能审核”，如需修改，请在“
              <span class="primary">评论管理</span>
              ”里面设置审核机制。
            </p>
          </a-col>
        </a-row>
      </div>
    </div>
    <div :class="$style.content">
      <p :class="$style.title">关联信息</p>
      <a-row>
        <a-col span="4" :class="$style.line40">文化点关联</a-col>
        <a-col span="20">
          <div :class="$style.line40">
            <BjButton class="btn-default" @click="onAddPoint()"><i class="ri-focus-line left" />选择文化点</BjButton>
          </div>
          <div :class="$style.pocBox">
            <div v-for="(item, index) in pocData" :key="item.id" :class="$style.pocItem">
              <div :class="$style.pocPad">
                <div :class="$style.img">
                  <img :src="item.cover.cover" />
                </div>
                <div :class="$style.name">
                  <p :title="item.name" class="ellipsis-width" :class="$style.title">{{ item.name }}</p>
                  <p :title="item.poc_type_name" class="ellipsis-width">{{ item.poc_type_name }}</p>
                </div>
                <div :class="$style.btn">
                  <i class="ri-eye-line primary" @click="onPreview(item)" />
                  <i class="ri-delete-bin-7-line" @click="onDeletePoc(index)" />
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div :class="$style.block" />
    <BjMap :visible.sync="visible.map" @change="mapChange" />
    <chooseMaterial :visible.sync="visible.choose" :allow="['image']" @getChoose="getChoose" />
    <choosePoint :visible.sync="visible.point" :select="pocData" :multiple="true" @change="pointChange" />
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import _ from 'lodash'

import BjMap from '@/components/BjMap.vue'
import chooseMaterial from '@/components/chooseMaterial'
import choosePoint from '@/components/choosePoint'
import selectMaterial from '@/components/selectMaterial'
import { manageService } from '@/service'

const service = new manageService()

export default {
  name: 'Detail',
  components: {
    BjMap,
    chooseMaterial,
    choosePoint,
    selectMaterial,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      params: {
        location: null,
        cover_type: 0,
        longitude: null,
        latitude: null,
        adcode: null,
        cover_atlas: [],
        summary: null,
        type_id: [],
        group_id: [],
        tags: [],
        is_original_declare: false,
        relation_poc: [],
        introduce: null,
        comment_status: 1,
      },
      visible: {
        map: false,
        choose: false,
        point: false,
      },
      groupData: [],
      tagShow: true,
      tag: null,
      pocData: [],
      typeData: [],
    }
  },
  computed: {
    coverShow() {
      return ['image', 'post'].includes(this.type)
    },
    summaryLength() {
      return this.params.summary ? this.params.summary.length : 0
    },
    // addShow() {
    //   if (this.coverShow) {
    //     if (this.params.cover_type === 0) {
    //       return this.params.cover_atlas.length !== 1
    //     } else {
    //       return this.params.cover_atlas.length !== 3
    //     }
    //   } else {
    //     return this.params.cover_atlas.length !== 1
    //   }
    // },
    typeName() {
      switch (this.type) {
        case 'image':
          return '图册介绍'
        case 'video':
          return '视频介绍'
        case 'audio':
          return '音频介绍'
        case 'post':
          return '正文'
      }
      return ''
    },
  },
  watch: {
    data: {
      handler(val) {
        if (val.id) {
          this.params.location = this.data.location
          this.params.latitude = this.data.latitude
          this.params.longitude = this.data.longitude
          this.params.real_location = this.data.real_location
          this.params.cover_atlas = _.cloneDeep(this.data.cover_atlas)
          this.params.summary = this.data.summary
          this.params.type_id = this.data.type_id_list
          this.params.group_id = this.data.group_id || []
          this.params.tags = this.data.tags
          this.params.cover_type = this.data.cover_type
          this.params.is_original_declare = this.data.is_original_declare === 0 ? false : true
          this.params.relation_poc = this.data.relation_poc
          this.pocData = this.data.relation_poc_list
          if (this.type === 'video' || this.type === 'audio' || this.type === 'image') {
            this.params.introduce = this.data.introduce
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.getGroup()
    if (this.$route.params.id === 'add') {
      this.getType()
      switch (this.type) {
        case 'post':
          this.params.cover_type = 0
          break
        case 'video':
          this.params.cover_type = 3
          break
        case 'audio':
          this.params.cover_type = 2
          break
        case 'image':
          this.params.cover_type = 1
          break
      }
    } else {
      this.autoGetTpye()
    }
  },
  methods: {
    async autoGetTpye() {
      const { data } = await service.typeList({
        parent_id: '',
      })
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.typeData = data
      if (this.params.type_id.length) {
        this.typeData.map(item => {
          if (item.id === this.params.type_id[0]) {
            this.autoLoadData([item], 1) // 获取下级 && 层级传进去 用第几层的id
          }
        })
      } else {
        setTimeout(() => {
          this.getAuto()
        }, 100)
      }
    },
    getAuto() {
      if (this.params.type_id.length) {
        this.typeData.map(item => {
          if (item.id === this.params.type_id[0]) {
            this.autoLoadData([item], 1) // 获取下级 && 层级传进去 用第几层的id
          }
        })
      } else {
        setTimeout(() => {
          this.getAuto()
        }, 100)
      }
    },
    async autoLoadData(e, index) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.typeList({
        parent_id: targetOption.id,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      // this.$forceUpdate()
      if (this.params.type_id.length - 1 > index) {
        data.map(item => {
          if (item.id === this.params.type_id[index]) {
            this.autoLoadData([item], index + 1)
          }
        })
      } else {
        this.typeData = _.cloneDeep(this.typeData)
      }
    },
    typeBlur() {
      if (this.params.type_id.length === 1) {
        this.$message.error('文化点类型仅支持二级及以上的条目，请重新选择。')
        this.params.type_id = []
      }
    },
    async onLoadData(e) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.typeList({
        parent_id: targetOption.id,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      this.typeData = _.cloneDeep(this.typeData)
    },
    onTag() {
      this.tagShow = false
      this.$nextTick(() => {
        this.$refs.tagInput.focus()
      })
    },
    mapChange(e) {
      this.params.location = e.location
      this.params.real_location = e.location
      this.params.longitude = e.longitude
      this.params.latitude = e.latitude
      this.params.adcode = e.adcode
    },
    onMapSelect() {
      this.visible.map = true
    },
    getChoose(data) {
      this.params.cover_atlas.push(data[0])
    },
    // onAddCover() {
    //   if (this.params.cover_atlas.length === 3) {
    //     this.$message.error('封面最多支持三张')
    //     return
    //   }
    //   this.visible.choose = true
    // },
    // onPreview(item) {
    //   let tmp = this.params.cover_atlas.map(item => ({
    //     cover: item.path,
    //     type: 'image',
    //   }))
    //   this.BjPreview(tmp, item.path)
    // },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'poc',
          id: item.id,
        },
      })
    },
    async getType() {
      const { data } = await service.typeList()
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.typeData = data
    },
    async getGroup() {
      const { data } = await service.groupList()
      this.groupData = data
    },
    tagChange() {
      this.tagShow = true
      if (this.tag) {
        this.params.tags.push(this.tag)
      }
      this.tag = null
    },
    deleteTag(index) {
      this.params.tags.splice(index, 1)
    },
    onAddPoint() {
      this.visible.point = true
    },
    pointChange(data) {
      this.pocData = _.cloneDeep(data)
    },
    onDeletePoc(index) {
      this.pocData.splice(index, 1)
    },
    // onDeleteImg(index) {
    //   this.params.cover_atlas.splice(index, 1)
    // },
    toGroup() {
      this.$router.push({
        name: 'contentGroup',
      })
    },
    coverChange(data) {
      if (data.length > 3) {
        this.$message.error('最多选择三张封面')
        return
      }
      this.params.cover_atlas = data
    },
    typeChange(e) {
      if (this.coverShow && this.params.cover_type === 1 && this.params.cover_atlas.length > 1) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this
        this.$confirm({
          content: '确定要切换单图吗？切换后会删除多余的封面。',
          onOk() {
            _this.params.cover_atlas = [{ ..._this.params.cover_atlas[0] }]
            _this.params.cover_type = e.target.value
          },
          onCancel() {
            Modal.destroyAll()
          },
        })
      } else {
        this.params.cover_type = e.target.value
      }
    },
  },
}
</script>

<style lang="less" module>
.detail {
  margin-top: 20px;

  :global {
    .ant-cascader-picker {
      width: 100%;
    }

    .ant-col-4 {
      color: #000;
      font-size: 14px;
      line-height: 24px;
      width: 143px;
    }

    .ant-col-20 {
      width: calc(100% - 143px);
    }

    .ant-input {
      height: 40px;
    }

    .ant-select {
      height: 40px;
    }

    .ant-select-selection__rendered {
      line-height: 40px;
    }

    .ant-select-selection--single {
      height: 40px;
    }
  }

  .box {
    background: #fff;
  }

  .setting {
    padding: 20px;
    width: 900px;

    .textarea {
      position: relative;

      span {
        position: relative;
        top: -24px;
        left: -22px;
        float: right;
      }
    }
  }
}

.cover-box {
  display: flex;
  margin-bottom: 10px;

  .cover-item {
    position: relative;
    width: 68px;
    height: 68px;
    margin-right: 10px;
    text-align: center;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }

    .btn {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 18px;
      line-height: 68px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);

      i {
        color: #fff;
      }
    }

    &:hover .btn {
      display: block;
    }
  }

  .cover-default {
    width: 68px;
    height: 68px;
    text-align: center;
    border: 2px dashed #f0f0f0;
    /* stylelint-disable-next-line no-descending-specificity */
    i {
      color: #5c5c5c;
      font-size: 30px;
      line-height: 68px;
    }
  }
}

.tag-box {
  display: flex;
  height: 40px;
  padding: 5px;
  border: 1px solid #eee;

  .tag-item {
    flex: 1;

    p {
      margin: 0;
      margin-left: 6px;
      color: #bbb;
      font-size: 14px;
      line-height: 30px;
    }

    .tag-text {
      position: relative;
      display: inline-block;
      width: 66px;
      height: 24px;
      margin: 2px 10px 0 0;
      color: #5c5c5c;
      font-size: 10px;
      line-height: 24px;
      text-align: center;
      background: #eee;
      border-radius: 4px;

      .text {
        padding: 0 12px 0 5px;
        text-align: left;
      }

      i {
        position: absolute;
        top: 0;
        right: 3px;
        font-size: 14px;
      }
    }
  }

  .tag-input {
    line-height: 30px;

    :global {
      .ant-input {
        height: 28px;
        border-radius: 0;
      }

      .ant-form-item {
        height: 30px;
        margin-bottom: 0;
      }

      .ant-form-item-control {
        line-height: 30px;
      }
    }
  }
}

.title {
  color: #000;
  font-size: 16px;
}
// .poc-box::after {
//   content: '';
//   width: 30%;
// }
.poc-box {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  .poc-pad {
    padding: 0 10px;
    display: flex;
    padding: 5px;
    border: 1px solid #eee;
  }
  .poc-item:nth-child(3n + 1) {
    padding-right: 10px;
  }
  .poc-item:nth-child(3n + 2) {
    padding: 0 10px;
  }
  .poc-item:nth-child(3n + 0) {
    padding-left: 10px;
  }
  .poc-item {
    width: 33.3%;
    height: 70px;
    margin-bottom: 20px;

    .img {
      position: relative;
      width: 60px;
      height: 60px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      flex: 1;
      padding-top: 5px;
      padding-left: 10px;

      p {
        margin-bottom: 6px;
        color: #5c5c5c;
        font-size: 12px;
      }

      .title {
        color: #000;
        font-size: 14px;
      }
    }
    /* stylelint-disable-next-line no-descending-specificity */
    .btn {
      width: 50px;
      padding-top: 16px;

      i {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }
}

.content {
  margin: 20px 0;
  padding: 20px;
  background: #fff;
}

.block {
  height: 40px;
}

.require {
  color: @error-color;
}

.locationbtn {
  display: inline-block;
  width: 84px;
  height: 28px;
  color: #fff;
  font-size: 12px;
  line-height: 28px !important;
  text-align: center;
  background: @primary-color;
  border-radius: 6px;
  cursor: pointer;

  i {
    margin-right: 3px;
    color: #fff;
    font-size: 16px;
    vertical-align: -3px;
  }
}

.group {
  :global {
    .ant-form-item {
      margin-bottom: 5px;
    }
  }
}

.line40 {
  line-height: 40px !important;
}

.location {
  :global {
    .ant-form-item {
      margin-bottom: 5px !important;
    }

    .ant-input {
      padding-right: 125px !important;
    }
  }
}
</style>
